











































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import BigNumber from "bignumber.js";
import filtersMixin from "@/mixins/filters";
import { fetchAPY } from "@/init";
import state, { isLoadedState } from "@/contract";
import { IndexedCurrency } from "@/types";
import { selectedNetwork } from "@/network";
import { ETH_MAINNET } from "@/address";

type SigInfo = {
    totalSupply: BigNumber;
    mintRate: BigNumber;
    supplyNextBlock: BigNumber;
    supplyThisBlock: BigNumber;

}

type SupplyInfo = {
    totalSupply: BigNumber;
    availableSupply: BigNumber;
    marketSupply: BigNumber;
}

@Component({
    name: "Stats",
    mixins: [filtersMixin],
    data: () => ({
        coins: state.coins
    }),
    computed: {
        ...mapState([
            'balanceChartData',
            'total',
            'fee',
            'adminFee',
            'sigTokenPrice',
        ])
    }
})
export default class Stats extends Vue {
    private APY1: number | null = null
    private APY2: number | null = null
    private APY3: number | null = null

    private sig: SigInfo | null = null
    private supply: SupplyInfo | null = null
    private accumulatedFees: BigNumber | null = null

    private coins: IndexedCurrency[] = state.coins;

    private chartOptions = {
        backgroundColor: "transparent",
        pieHole: 0.5,
        pieSliceTextStyle: {
            color: "transparent"
        },
        legend: "none",
        chartArea: { width: "100%", height: "100%" },
        colors: ['#F269A7', '#CE81ED', '#E4CBEE', '#f8f8f8']
    }

    async mounted() {
        setTimeout(async () => this.updateStats(), 5000)
        setInterval(async () => this.updateStats(), 120000)
    }

    async updateStats() {
        this.APY1 = null
        this.APY2 = null
        this.APY3 = null
        this.sig = null
        this.supply = null
        this.accumulatedFees = null


        fetchAPY(1).then((val) => this.APY1 = val)
        fetchAPY(2).then((val) => this.APY2 = val)
        fetchAPY(3).then((val) => this.APY3 = val)

        this.getSigInfo().then(val => this.sig = val)
        this.getSigSupply().then(val => this.supply = val)
        this.getTotalFees().then(val => this.accumulatedFees = val)
    }

    async getTotalFees(): Promise<BigNumber> {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet")}

        // TODO: why this doesnt work
        // let sum = new BigNumber(0)

        // for (const coin of this.coins) {
        //     const amount = await state.swapContract.methods.admin_balances(coin.index).call()
        //     console.log('amount', amount)
        //     // sum += amount * precision
        //     sum = sum.plus( new BigNumber(amount).div(coin.precision) )
        // }

        // return sum

        const adminBalances = await Promise.all(
            state.coins.map(async (coin, i) => {
                if (!isLoadedState(state)) { throw new Error("State Not Loaded yet")}

                const amount = await state.swapContract.methods.admin_balances(i).call()

                return {
                    amount: new BigNumber(amount).div(coin.precision),
                    symbol: coin.symbol
                }
            })
        )

        return adminBalances.reduce((sum: BigNumber, { amount }: any) => sum.plus(amount), new BigNumber(0))

    }

    async getUniswapTradingData() {
        // fetchTokenPriceToETH(state.sigToken)

    }

    async getSigSupply(): Promise<SupplyInfo> {
        try {
            const { totalSupply, marketSupply, availableSupply } = await fetch(`/api/supply`).then(res => res.json())

            return {
                totalSupply: new BigNumber(totalSupply),
                marketSupply: new BigNumber(marketSupply),
                availableSupply: new BigNumber(availableSupply),
            }
        } catch (err) {
            throw Error('Cant fetch Supply')
        }
    }

    async getSigInfo (): Promise<SigInfo> {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet")}

        const block = await state.web3.eth.getBlockNumber()

        // const sigToken = new state.web3.eth.Contract()
        const blockStep = selectedNetwork.chainId === ETH_MAINNET ? 4 : 60 * 16;

        // const supplyNextBlock = await state.sigToken.contract.methods.totalRewardAtBlock()
        const supplyNextBlock = await state.rewardsContract.methods.totalRewardAtBlock(block + blockStep).call()
        const supplyThisBlock = await state.rewardsContract.methods.totalRewardAtBlock(block).call()

        console.log('mint', supplyNextBlock, supplyThisBlock, supplyNextBlock - supplyThisBlock)

        const mintRate = new BigNumber(supplyNextBlock).minus(supplyThisBlock).div(blockStep)

        const totalSupply = await state.sigToken.contract.methods.totalSupply().call()

        // because 30% devfund was burned, times 70%
        /* eslint-disable @typescript-eslint/camelcase */
        const FIX_mintRate = mintRate.times(70).div(100);
        const FIX_totalSupply = new BigNumber(totalSupply).times(70).div(100);

        return {
            totalSupply: FIX_totalSupply,
            mintRate: FIX_mintRate,
            supplyNextBlock: new BigNumber(supplyNextBlock),
            supplyThisBlock: new BigNumber(supplyThisBlock),
        }
        /* eslint-enable @typescript-eslint/camelcase */
    }
}
